import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import NProgress from 'nprogress'
import { Message, Loading } from 'element-ui'
import 'nprogress/nprogress.css'
import { loadings, requestSign } from '@/utils/http.js'


Vue.use(VueRouter)

/*
  前端权限管理 :
  使用登录的role_id做判断
   1超级管理员
   2普通管理员 冬妃  除了系统设置不能看  别的都能看(系统设置也可看)
   3教研主管 徐老师  统计信息 除了批改 录题 反馈统计不能看  别的都能看
   18通用账号  只给知识点章节模块
*/

const routes = [
  {
    path: '/',
    name: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue'),
  },
  // index页面的位置不能变，在home页面直接拿的index的children的数据来渲染菜单栏的
  {
    path: '/index',
    name: 'index',
    component: () => import('@/views/Home.vue'),
    redirect: '/homepage',
    children: [
      {
        path: '/homepage',
        meta: {
          title: '首页',
          isRole: [1, 2, 3, 18]
        },
        component: () => import('@/views/homepage/index.vue')
      },
      {
        path: '/studentAdmin',
        meta: {
          title: '学员管理',
          isRole: [1, 2]
        },
        children: [
          {
            path: '/studentAdmin/student',
            meta: {
              title: '学员管理',
              isRole: [1, 2]
            },
            component: () => import('@/views/studentAdmin/student/index.vue')
          },
          {
            path: '/studentAdmin/addStudent',
            meta: {
              title: '新增学员',
              isRole: [1, 2],
              hidden: true

            },
            component: () => import('@/views/studentAdmin/student/components/form.vue')
          },
        ],
        component: () => import('@/views/About.vue')
      },
      {
        path: '/educationSchool',
        meta: {
          title: '教育局学校',
          isRole: [1, 2]

        },
        component: () => import('@/views/About.vue'),
        children: [
          {
            path: '/educationSchool/education',
            meta: {
              title: '教育局管理',
              isRole: [1, 2]

            },
            component: () => import('@/views/educationSchool/education/index.vue')
          },
          {
            path: '/educationSchool/school',
            meta: {
              title: '学校管理',
              isRole: [1, 2]

            },
            component: () => import('@/views/educationSchool/school/index.vue')
          },
          {
            path: '/educationSchool/school_group',
            meta: {
              title: '学校分组',
              isRole: [1, 2]

            },
            component: () => import('@/views/educationSchool/school_group/index.vue')
          },
          {
            path: '/educationSchool/agent',
            meta: {
              title: '代理商管理',
              isRole: [1, 2]
            },
            component: () => import('@/views/educationSchool/agent/index.vue')
          },
          {
            path: '/educationSchool/grade',
            meta: {
              title: '年级管理',
              isRole: [1, 2]

            },
            component: () => import('@/views/educationSchool/grade/index.vue')
          },
          {
            path: '/educationSchool/class',
            meta: {
              title: '班级管理',
              isRole: [1, 2]

            },
            component: () => import('@/views/educationSchool/class/index.vue')
          },
          {
            path: '/educationSchool/teacher',
            meta: {
              title: '教师管理',
              isRole: [1, 2]

            },
            component: () => import('@/views/educationSchool/teacher/index.vue')
          },

        ],
      },
      {
        path: '/teachingResource',
        meta: {
          title: '标注管理',
          isRole: [1, 2, 3, 18]
        },
        component: () => import('@/views/About.vue'),
        children: [
          {
            path: '/book',
            meta: {
              title: '教材',
              isRole: [1, 2, 3]
            },
            component: () => import('@/views/teachingResource/textbook/index.vue')
          },
          {
            path: '/chapter',
            meta: {
              title: '章节',
              isRole: [1, 2, 3, 18]
            },
            component: () => import('@/views/teachingResource/chapter/index.vue')
          },
          {
            path: '/knowledge',
            meta: {
              title: '知识点',
              isRole: [1, 2, 3, 18]
            },
            component: () => import('@/views/teachingResource/knowledge/index.vue')
          },
          {
            path: '/ability',
            meta: {
              title: '能力素养',
              isRole: [1, 2, 3, 18]
            },
            component: () => import('@/views/teachingResource/ability/index.vue')
          },
          {
            path: '/classes/textbook',
            meta: {
              title: '教材管理',
              isRole: [1, 2, 3],
              hidden: true
            },
            name: '/classes/textbook',
            component: () => import('@/views/classes/relationTextbook/index.vue'),
          },
        ]
      },
      {
        path: '/paperAdmin',
        meta: {
          title: '试卷管理',
          isRole: [1, 2, 3]
        },
        component: () => import('@/views/About.vue'),
        children: [
          {
            path: '/source',
            meta: {
              title: '试卷来源',
              isRole: [1, 2, 3]
            },
            component: () => import('@/views/paperAdmin/source/index.vue')
          },
          {
            path: '/dis',
            meta: {
              title: '试卷地区',
              isRole: [1, 2, 3]
            },
            component: () => import('@/views/paperAdmin/dis/index.vue')
          },
          {
            path: '/subject',
            meta: {
              title: '试卷科目',
              isRole: [1, 2, 3]
            },
            component: () => import('@/views/paperAdmin/subject/index.vue')
          },
          {
            path: '/paperType',
            meta: {
              title: '题目类型',
              isRole: [1, 2, 3]
            },
            component: () => import('@/views/paperAdmin/type/index.vue')
          },
          {
            path: '/paperCategoty',
            meta: {
              title: '题目类别',
              isRole: [1, 2, 3]
            },
            component: () => import('@/views/paperAdmin/category/index.vue')
          },
          {
            path: '/paperShowType',
            meta: {
              title: '题目显示类型',
              isRole: [1, 2, 3]
            },
            component: () => import('@/views/paperAdmin/showType/index.vue')
          },
          {
            path: '/paperRuleTemplate',
            meta: {
              title: '组卷规则模板',
              isRole: [1, 2, 3]
            },
            component: () => import('@/views/paperAdmin/paperRuleTemplate/index.vue')
          },
          {
            path: '/paperRuleTemplateDetail',
            meta: {
              title: '组卷规则模板录入详情',
              isRole: [1, 2, 3],
              hidden: true
            },
            component: () => import('@/views/paperAdmin/paperRuleTemplate/detail.vue')
          },
          {
            path: '/paperRuleTemplate/paperInfo',
            meta: {
              title: '组卷规则模板试卷详情',
              isRole: [1, 2, 3],
              hidden: true
            },
            component: () => import('@/views/paperAdmin/paperRuleTemplate/component/paperInfo.vue')
          },
        ]
      },
      {
        path: '/statisticsInfo',
        meta: {
          title: '统计信息',
          isRole: [1, 2, 3]
        },
        name: 'statisticsInfo',
        component: () => import('@/views/About.vue'),
        children: [
          {
            path: '/topicAdmin',
            meta: {
              title: '录题统计',
              isRole: [1, 2, 3]

            },
            name: 'topicAdmin',
            component: () => import('@/views/statisticalInformation/topic/index.vue'),
          },
          {
            path: '/topicAdmin/usertopicList',
            meta: {
              title: '用户试卷列表',
              isRole: [1, 2, 3],
              hidden: true
            },
            name: 'topicAdmin_usertopicList',
            component: () => import('@/views/statisticalInformation/topic/components/index.vue'),
          },
          {
            path: '/topicAdmin/usertopicList/info',
            meta: {
              title: '用户试卷详情',
              isRole: [1, 2, 3],
              hidden: true
            },
            name: 'usertopicList_info',
            component: () => import('@/views/statisticalInformation/topic/components/info.vue'),
          },
          {
            path: '/changeAdmin',
            meta: {
              title: '批改统计',
              isRole: [1, 2, 3]
            },
            name: 'changeAdmin',
            component: () => import('@/views/statisticalInformation/change/index.vue'),
          },
          {
            path: '/changeAdmin/paperlist',
            meta: {
              title: '教师改卷列表',
              isRole: [1, 2, 3],
              hidden: true
            },
            name: 'paperlist',
            component: () => import('@/views/statisticalInformation/change/components/info.vue'),
          },
          {
            path: '/changeAdmin/paperlist/paperInfo',
            meta: {
              title: '教师改卷详情',
              isRole: [1, 2, 3],
              hidden: true
            },
            name: 'paperlist',
            component: () => import('@/views/statisticalInformation/change/components/paperInfo.vue'),
          },
          {
            path: '/forum_statics',
            meta: {
              title: '答疑统计',
              isRole: [1, 2, 3]
            },
            name: 'forum_statics',
            component: () => import('@/views/statisticalInformation/forum/index.vue'),
          },
          {
            path: '/forum_statics/teacher_list',
            meta: {
              title: '教师答疑列表',
              hidden: true,
              isRole: [1, 2, 3]
            },
            name: '/forum_statics/teacher_list',
            component: () => import('@/views/statisticalInformation/forum/components/info.vue'),
          },
          {
            path: '/forum_statics/detail',
            meta: {
              title: '答疑详情',
              hidden: true,
              isRole: [1, 2, 3]
            },
            name: '/forum_statics/detail',
            component: () => import('@/views/statisticalInformation/forum/components/detail.vue'),
          },
          {
            path: '/teachingMaterials',
            meta: {
              title: '讲义统计',
              isRole: [1, 2, 3],

            },
            name: 'teachingMaterials',
            component: () => import('@/views/statisticalInformation/teachingMaterials/index.vue'),
          },
          {
            path: '/teachingMaterials/info',
            meta: {
              title: '讲义详情',
              isRole: [1, 2, 3],
              hidden: true
            },
            name: '/teachingMaterials/info',
            component: () => import('@/views/statisticalInformation/teachingMaterials/components/info.vue'),
          },
          {
            path: '/feedback_statics',
            meta: {
              title: '反馈统计',
              isRole: [1, 2, 3]

            },
            name: 'feedback_statics',
            component: () => import('@/views/statisticalInformation/feedback/index.vue'),
          },
          {
            path: '/feedback_list',
            meta: {
              title: '反馈列表',
              isRole: [1, 2, 3],
              hidden: true
            },
            name: 'feedback_list',
            component: () => import('@/views/statisticalInformation/feedback/components/list.vue'),
          },



          {
            path: '/topicbumber',
            meta: {
              title: '题目数统计',
              isRole: [1, 2, 3]
            },
            name: 'topicNumber',
            component: () => import('@/views/statisticalInformation/topicNunmer/index.vue'),
          },
          {
            path: '/topicbumber/topicbumber_info',
            meta: {
              title: '科目详情',
              hidden: true,
              isRole: [1, 2, 3]
            },
            name: 'topicbumber_info',
            component: () => import('@/views/statisticalInformation/topicNunmer/components/info.vue'),
          },


          {
            path: '/testpaper_statics',
            meta: {
              title: '组卷统计',
              isRole: [1, 2, 3]

            },
            name: 'testpaper_statics',
            component: () => import('@/views/statisticalInformation/testpaperStatics/index.vue'),
          },

          {
            path: '/testpaper_statics/teacherInfo',
            meta: {
              title: '教师组卷详情',
              isRole: [1, 2, 3],
              hidden: true
            },
            name: '/testpaper_statics/teacherInfo',
            component: () => import('@/views/statisticalInformation/testpaperStatics/components/teacherInfo.vue'),
          },
          {
            path: '/testpaper_statics/paperinfo',
            meta: {
              title: '试卷详情',
              isRole: [1, 2, 3],
              hidden: true
            },
            name: '/testpaper_statics/paperinfo',
            component: () => import('@/views/statisticalInformation/testpaperStatics/components/paperinfo.vue'),
          },
        ],
      },
      {
        path: '/classes',
        meta: {
          title: '教学班级',
          isRole: [1, 2]

        },
        name: 'all',
        component: () => import('@/views/About.vue'),
        children: [
          {
            path: '/classes/class',
            meta: {
              title: '教学班级',
              isRole: [1, 2]

              // icon: 'el-icon-s-home',
            },
            name: 'user',
            component: () => import('@/views/classes/class/index.vue'),
          },
          {
            path: '/classes/teacher',
            meta: {
              title: '教师',
              isRole: [1, 2],
              hidden: true

              // icon: 'el-icon-s-home',
            },
            name: 'user',
            component: () => import('@/views/classes/teacher/index.vue'),
          },
          {
            path: '/classes/classroom',
            meta: {
              title: '课室',
              isRole: [1, 2]

              // icon: 'el-icon-s-home',
            },
            name: 'user',
            component: () => import('@/views/classes/classroom/index.vue'),
          },
          {
            path: '/classes/course',
            meta: {
              title: '课程',
              isRole: [1, 2]

              // icon: 'el-icon-s-home',
            },
            name: 'user',
            component: () => import('@/views/classes/course/index.vue'),
          },

        ]
      },

      {
        path: '/setting',
        meta: {
          title: '系统设置',
          isRole: [1, 2]
        },
        children: [
          {
            path: '/settingall',
            meta: {
              title: '管理权限',
              isRole: [1, 2]

            },
            name: 'all',
            children: [
              {
                path: '/setting/user',
                meta: {
                  title: '角色管理',
                  icon: 'el-icon-s-home',
                  isRole: [1, 2]

                },
                name: 'user',
                component: () => import('@/views/setting/user/index.vue'),
              },
              {
                path: '/setting/admin',
                meta: {
                  title: '管理员列表',
                  isRole: [1, 2]

                },
                name: 'admin',
                component: () => import('@/views/setting/admin/index.vue'),
              },
              {
                path: '/settidng/permission',
                meta: {
                  title: '权限规则',
                  icon: 'el-icon-s-home',
                  isRole: [1, 2]
                },
                name: 'permission',
                component: () => import('@/views/setting/permission/index.vue'),
              },
            ],
            component: () => import('@/views/About.vue'),
          },
          {
            path: '/apiLog',
            meta: {
              title: 'api日志',
              isRole: [1, 2],
            },
            name: 'apiLog',
            // children: [],
            component: () => import('@/views/apiLog/index.vue'),
          },
          {
            path: '/apiLogRank',
            meta: {
              title: 'api日志排行',
              isRole: [1, 2],
            },
            name: 'apiLogRank',
            // children: [],
            component: () => import('@/views/apiLogRank/index.vue'),
          },

        ],
        name: 'setting',
        component: () => import('@/views/About.vue')
      },

      // {
      //   path: '/tableTest',
      //   meta: {
      //     title: '调试',
      //   },
      //   component: () => import('@/views/tableTest/index.vue')
      // },
    ]
  },
  {
    path: '*',
    meta: {
      title: '404',

      icon: '',
      roles: ['admin']
    },
    name: "notfound",
    component: () => import('@/views/404.vue')
  }
]
const router = new VueRouter({
  routes
})




const routerPush = router.push;
router.push = path => {
  // 判断下当前路由是否就是要跳转的路由
  if (router.currentRoute.fullPath.includes(path)) return;
  return routerPush.call(router, path);
}
//路由白名单 ,配置的路由直接放行
const dischargedList = ['/login', '/', '/404']   //'/index','/homepage',
// 判断是否登录
router.beforeEach((to, from, next) => {
  // setRouter()
  // 跳转时，关闭之前发出的请求所产生的loading 所以后续页面的loading都尽量在mounted里面发出
  requestSign.setNumber(0)
  if (loadings) {
    loadings.close()
  }
  let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
  // 显示进度条
  NProgress.start()
  if (dischargedList.indexOf(to.path) !== -1) {
    next()
  } else {
    if (userInfo) {
      var time = Date.parse(new Date()).toString(); //精确到毫秒
      time = time.substr(0, 10); //精确到秒
      if (userInfo.exp - time <= 180) {
        window.localStorage.removeItem('userInfo')
        userInfo = ''
        setTimeout(() => {
          Message.error('登录已过期，请重新登录')
          return true
        }, 100);
      }
    }
    // 用户token不存在的时候就判定未登录
    if (!userInfo) {
      next('/login')
    }
    // if (to.meta.isRole.indexOf(userInfo.Roleid) == -1) {
    //   next('/homepage')
    // }
  }
  // 查看是否存在路由白名单里面
  // console.log('store.state.navigation', store.state.navigation)
  if (dischargedList.indexOf(to.path) == -1) {
    if (store.state.navigation.findIndex(item => item.path == to.path) == -1) {
      store.commit('SET_NAVIGATION', to)
    } else {
      store.commit('DEL_NAVIGATION', from)
    }
  } else {
    store.commit('CLEAR_NAVIGATION')
  }
  next()
})

router.afterEach(() => {
  // 完成进度条
  NProgress.done()
})

export default router
