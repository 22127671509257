<template>
  <div id="app">
    <router-view></router-view>

  </div>
</template>

<script>

export default {
  name: 'app',
  components: {
  },
  mounted () {

  }

}
</script>

<style lang="scss" >
#app {
  width: 100%;
  // height: 100vh;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dd {
  margin: 0;
  color: black;
}
ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
img {
  border: none;
  vertical-align: top;
}
a {
  text-decoration: none;
}
.top-floor {
  z-index: 9999 !important;
}
.text_btn_primary {
  cursor: pointer;
  color: #409eff;
  padding: 10px;
  display: inline-block;
}
.text_btn_danger {
  cursor: pointer;
  color: #ff0000;
  padding: 10px;
  display: inline-block;
}

.weishanhe {
  color: #3f9eff;
  background: #eff8ff;
  padding: 5px 18px;
  display: inline-block;
  border-radius: 5px;
}

.pass {
  color: #20c192;
  background: #cff7ea;
  padding: 0 15px;
  height: 28px;
  line-height: 28px;
  display: inline-block;
  border-radius: 5px;
}

.notpass {
  color: #fe765f;
  background: #fde9e9;
  padding: 0 12px;
  height: 28px;
  line-height: 28px;
  display: inline-block;
  border-radius: 5px;
}

::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

.operyion_btn_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
.el-tooltip__popper {
  max-width: 800px;
}
::v-deep .el-form-item--medium .el-form-item__content,
.el-form-item--medium .el-form-item__label {
  line-height: 25px;
}

.operyion_img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.opertion_image_warp {
  display: inline-block;
}
</style>
