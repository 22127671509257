import axios from 'axios'
import basicUrl from './basicUrl'
import router from '@/router'
import { Message, Loading } from 'element-ui'
import getMd5Form from './getMd5Form.js'


export const instance = axios.create({
  baseURL: basicUrl,
  timeout: 6000000000000,
});

export const http_file = axios.create({
  baseURL: basicUrl,
  timeout: 6000000000000,
  headers: {
    'Content-Type': 'multipart/form-data'
  },
  transformRequest: [(data) => {
    const formData = new FormData()
    for (let key in data) {
      formData.append(key, data[key])
    }
    return formData
  }]
})

function getTime () {
  if (window.localStorage.getItem('userInfo')) {
    let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
    var time = Date.parse(new Date()).toString(); //精确到毫秒
    time = time.substr(0, 10); //精确到秒
    if (userInfo) {
      if (userInfo.exp - time <= 180) {
        window.localStorage.removeItem('userInfo')
        setTimeout(() => {
          Message.error('登录已过期，请重新登录')
          return true
        }, 100);
        router.push('/login')
      }
    }
  }
}

//暴露出去给router跳转的时候清除loading
export var requestSign = {
  number: 0,
  setNumber: function (number) {
    requestSign.number = number
  }
}

export var loadings
function openLoading () {
  // 开启全局loading 
  if (!requestSign.number) {
    loadings = Loading.service({
      fullscreen: true,
      target: document.querySelector('.home_right'),
      text: '正在加载中!',
      spinner: 'el-icon-loading'
    });
    setTimeout(() => {
      if (loadings) {
        loadings.close()
        // 
      }
    }, 60000);
  }
  requestSign.number += 1
}
function closeLoading () {
  setTimeout(() => {
    requestSign.number -= 1
    if (requestSign.number < 0 || requestSign.number == 0) {
      loadings.close()
    }
  }, 500);
}

// 设置放行路由，放行错误控制
var role = [
  '/api/v2/bureau/export_teacher',
  '/api/v2/stats/correct_export',
  '/api/v2/stats/cp_export',
  '/api/v2/stats/record_export',
  '/api/v2/stats/course_export',
  '/api/v2/stats/lecture_export',
  '/api/v2/stats/question_error_export',
  '/api/v2/stats/discuss_export'

]
function addInterceptors (face) {
  face.interceptors.request.use(config => {
    if (config.method == 'get') {
      if (!config.params) {
        config.params = {}
      }
      config.params.sign = getMd5Form(config.params)
    } else {
      if (!config.data) {
        config.data = {}
      }
      config.data.sign = getMd5Form(config.data)
    }
    getTime()
    if (window.localStorage.getItem('userInfo')) {
      let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
      config.headers['Authorization'] = `Bearer ${userInfo.token}`
    }
    openLoading()
    return config
  })
  face.interceptors.response.use(async config => {
    closeLoading()

    if (role.findIndex(item => item == config.config.url) != -1) {
      return config
    }
    config = config.data

    return await new Promise((resolve, reject) => {

      if (!config.status) {
        return
      }
      if (config.status == 200) {
        resolve(config)
      } else {
        // 当状态码不为200的时候，直接拦截
        if (config.msg == 'error') {
          Message.error(config.data.msg)
        } else {
          Message.error(config.msg)
        }
        if (config.status == 410000) {
          window.localStorage.removeItem('userInfo')
          router.push('/login')
        }
        reject(config)
      }
    })
  })
}

addInterceptors(instance)
addInterceptors(http_file)
