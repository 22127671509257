<template>
  <div>
    <el-submenu :index="group.path"
                v-permission="group">
      <template slot="title">
        <span>{{group.meta.title}}</span>
      </template>
      <el-menu-item-group>
        <menu-item v-for="item in group.children"
                   :item="item"
                   :key="item.path" />
      </el-menu-item-group>
    </el-submenu>
  </div>
</template>

<script>
import MenuItem from './menuItem.vue'

export default {
  name: 'MenuGroup',
  components: {
    MenuItem,
  },
  props: ['group'],
  data () {
    return {
      isHidden: true
    }
  },
  mounted () {
    // if (this.group.child && this.group.child.length) {
    //   this.isHidden = this.group.child.every(item => {
    //     // console.log('item', item);
    //     return item.is_show_path == 1
    //   })
    //   // console.log('isHidden', this.isHidden);
    // }

  },
  methods: {
    toPath (item) {
      console.log('item', item);
      // return
      if (this.$route.path == item.menu_path) {
        return
      }
      this.$router.push({ path: item.menu_path })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-menu-item-group__title {
  display: none;
}
::v-deep .el-submenu__title:hover {
  background: #409eff !important;
  color: white;
}
// 子菜单项激活样式
::v-deep .el-menu-item.is-active {
  font-size: 16px;
  font-weight: bold;
  background: white !important;
  color: #409eff !important;
}

// 子菜单项鼠标悬浮样式
::v-deep .el-menu-item {
  transition: all 0.3s ease;
  font-size: 14px;
  color: white;
  &:hover {
    background: white !important;
    color: #409eff !important;
  }
}
</style>
