<template>
  <div>
    <el-dialog title="表格列显示"
               :visible.sync="dialogVisible"
               v-dialogDrag
               width="600px"
               :before-close="handleClose">
      <div class="text">
        <div>显示字段：{{showColumns.length}}</div>
        <div> </div>
        <div>隐藏字段：{{hideColumns.length}}</div>
      </div>
      <div class="warp">
        <div class="left">
          <div class="item"
               v-for="(item,index) in showColumns"
               @click="changeStatus(item)"
               :key="index">
            {{item.label}}
          </div>
        </div>
        <div class="center">
          <div>
            <i class="el-icon-arrow-left"></i>
          </div>
          <div>
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div class="right">
          <div class="item"
               v-for="(item,index) in hideColumns"
               @click="changeStatus(item)"
               :key="index">
            {{item.label}}
          </div>
        </div>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      columns: []
    }
  },
  computed: {
    showColumns () {
      let arr = []
      this.columns.forEach(item => {
        if (item.isShow) {
          arr.push(item)
        }
      })
      return arr
    },
    hideColumns () {
      let arr = []
      this.columns.forEach(item => {
        if (!item.isShow) {
          arr.push(item)
        }
      })
      return arr
    }
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
    },
    changeStatus (item) {
      if (item.label == '操作') return
      item.isShow = !item.isShow
    },
    save () {
      this.$parent.getColumns(JSON.parse(JSON.stringify(this.columns)))
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.warp {
  height: 300px;
  display: flex;
  .left {
    flex: 1;
    height: 300px;
    overflow-y: auto;
    border: 1px solid #f2f2f2;
    padding: 15px;
    padding-top: 0px;
  }
  .center {
    width: 40px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
  }
  .right {
    flex: 1;
    height: 300px;
    overflow-y: auto;
    border: 1px solid #f2f2f2;
    padding: 15px;
    padding-top: 0px;
  }
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.text {
  display: flex;
  padding: 5px 0;
  font-size: 14px;
  color: #aaaaaa;
  div {
    &:nth-child(1) {
      flex: 1;
    }
    &:nth-child(2) {
      width: 50px;
    }
    &:nth-child(3) {
      flex: 1;
    }
  }
}
.item {
  height: 40px;
  line-height: 40px;
}
.left .item {
  &:hover {
    color: red;
    cursor: pointer;
  }
}
.right .item {
  &:hover {
    color: #419eff;
    cursor: pointer;
  }
}
</style>

