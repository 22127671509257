import Vue from 'vue'
import Vuex from 'vuex'
// import router from '@/router'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    // 表格高度
    tableHeight: 0,
    tags: [],
    // 渲染菜单使用
    menuList: [],
    // 动态注册路由使用
    routerList: [],
    userInfo: {},
    // 菜单颜色,
    theme: {
      menuColor: ' #66B1FF',
      menuTextColor: '#DADCDE',
      menuActiveColor: 'white',
      activeColor: 'white',
      navBarColor: '#FFFFFF'
    },
    userInfo: {
      id: 86
    },
    // 面包屑导航
    navigation: []
  },
  getters: {
  },
  mutations: {
    // 对tag进行修改
    SET_TAGE (state, item) {
      // 去重，防止记录相同路由
      for (let index = 0; index < state.tags.length; index++) {
        if (state.tags[index].path == item.path) {
          // state.tags.splice(index, 1)
          return
        }
        if (state.tags[index].path == '/' || state.tags[index].name == 'notfound') {
          state.tags.splice(index, 1)
        }
      }
      if (item.path == '/login') {
        // console.log('登录页面，不允许记录');
      } else {
        state.tags.push(item)
      }
    },
    // 删除tag
    DEL_TAG (state, index) {
      state.tags.splice(index, 1)
    },
    // 修改theme
    SET_THEME (state, theme) {
      state.theme = theme
    },
    SET_TABLE_HEIGHT (state, height) {
      state.tableHeight = height
    },
    SET_USERINFO (state, info) {
      state.userInfo = info
    },
    SET_NAVIGATION (state, to) {
      let obj = {
        path: to.path,
        meta: to.meta
      }
      if (!to.meta.hidden) {
        window.sessionStorage.setItem('navigation', JSON.stringify(obj))
        state.navigation = [obj]
      } else {
        if (state.navigation == 0) {
          state.navigation = [JSON.parse(window.sessionStorage.getItem('navigation'))]
        }
        state.navigation.push(to)
      }
    },
    DEL_NAVIGATION (state, from) {
      if (state.navigation.findIndex(item => item.path == from.path) != -1) {
        state.navigation.splice(state.navigation.findIndex(item => item.path == from.path), 1)
      }
    },
    CLEAR_NAVIGATION (state) {
      state.navigation = []
    }
  },
  actions: {
  },
  modules: {
  }
})

window.addEventListener('load', () => {
  store.commit('SET_TABLE_HEIGHT', document.body.clientHeight - 300)
})
window.addEventListener('resize', () => {
  store.commit('SET_TABLE_HEIGHT', document.body.clientHeight - 300)
})
export default store

