import CryptoJS from 'crypto-js';
import api_key from './api_key.js'

const getMd5Form = function (form) {
  form = JSON.parse(JSON.stringify(form))
  let str = ''
  form.api_key = api_key;
  let keys = Object.keys(form);
  keys.sort().forEach(item => {
    if (form[item] === null) {
      form[item] = ''
    }
    // if (Array.isArray(form[item])) {
    //   str += JSON.stringify(form[item])
    // }
    if (typeof form[item] == 'object') {
      if (JSON.stringify(form[item]) != '{}') {
        str += JSON.stringify(form[item])
      } else {
        str += JSON.stringify([])
      }
    }
    else {
      str += typeof form[item] != 'undefined' ? form[item] : '';
    }
  });
  return CryptoJS.MD5(str).toString();
}

export default getMd5Form
