<template>
  <div>
    <menu-group v-if="item.children"
                :group="item" />
    <div v-else>
      <el-menu-item @click.native="toPath(item)"
                    v-permission="item"
                    v-if="!item.meta.hidden"
                    :index="item.path">
        <!-- <img :src="item.meta.icon"
             class="left_menu_icon"
             alt=""> -->
        <span v-if="!this.$parent.$parent.isCollapse">{{item.meta.title}}
        </span>
      </el-menu-item>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuItem',
  props: ['item'],
  data () {
    return {
    }
  },
  mounted () {
    // console.log('item', this.item);
  },
  methods: {
    toPath (item) {
      // console.log('item', item);
      // return
      if (this.$route.path == item.path) {
        return
      }
      this.$router.push({ path: item.path })
    }
  },
}
</script>

<style lang="scss" scoped>
/*隐藏文字*/
::v-deep .el-menu--collapse .el-submenu__title span {
  display: none;
}
/*隐藏 > */
::v-deep .el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
  display: none;
}

// 子菜单项鼠标悬浮样式
::v-deep .el-menu-item {
  transition: all 0.3s ease;
  font-size: 14px;
  color: white;
  &:hover {
    background: white !important;
    color: #409eff !important;
  }
}

::v-deep .el-submenu__title:hover {
  background: #409eff !important;
  color: white;
}
// 子菜单项激活样式
::v-deep .el-menu-item.is-active {
  font-size: 16px;
  font-weight: bold;
  background: white !important;
  color: #409eff !important;
}
</style>