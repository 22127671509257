import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
// import BASICCOMMON from './components/basic_common/index.vue'
// import BASICTOPIC from './components/basic_topic/index.vue'
// import BASICTOPICHEADER from './components/basic_topic/header.vue'
import Pagination from '@/views/components/Pagination'
import { instance, http_file } from './utils/http.js'
import basicUrl from './utils/basicUrl'
// 引入富文本编辑器
import VueUeditorWrap from 'vue-ueditor-wrap'
import getFile from './utils/file'
import Group from '@/components/menuGroup.vue'
import './utils/directives'
// import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
import * as echarts from 'echarts';
import Table from '@/views/components/table/tables.vue'

// 导入表格树
import TreeTable from 'vue-table-with-tree-grid'

import getMd5Form from '@/utils/getMd5Form.js'
Vue.prototype.$getMd5Form = getMd5Form


Vue.prototype.$forbid = function () {
  //禁止软键盘弹出
  document.activeElement.blur();
}


// 获取路由动态注册
// setRoutes()

Vue.prototype.$echarts = echarts

Vue.use(preview)
// Vue.use(Viewer);
// Viewer.setDefaults({
//   Options: { "inline": true, "button": true, "navbar": true, "title": true, "toolbar": true, "tooltip": true, "movable": true, "zoomable": true, "rotatable": true, "scalable": true, "transition": true, "fullscreen": true, "keyboard": true, "url": "data-source" }
// });

// 组件全局注册 表格树
Vue.component('TreeTable', TreeTable)
//选择文件功能 
Vue.prototype.$get_file = getFile
// bsaic请求基底
Vue.prototype.$basicUrl = basicUrl
// 挂载请求
Vue.prototype.$http = instance
// 上传文件功能
Vue.prototype.$http_file = http_file
// 富文本编辑器
Vue.component('VueUeditorWrap', VueUeditorWrap)
// 分页通用组件
Vue.component('pagination', Pagination)
// 应用表格组件
Vue.component('Table', Table)

Vue.component('menu-group', Group)
// 自定义指令

Vue.directive('permission', {
  inserted: function (el, binding) {
    // 逻辑
    /*
      userinfo用来存储用户信息，路由权限控制，router里面有个roles  
    */
    let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
    let roles = binding.value.meta.isRole
    if (roles.indexOf(userInfo.Roleid) === -1) {
      el.style.display = 'none'
    }
  },
})

// 统一配置ueditor配置项目
var ueditorConfig = {
  UEDITOR_HOME_URL: '/ueditor/',  // 需要令此处的URL对等于 ueditor.config.js 中的配置。
  // 编辑器不自动被内容撑高
  autoHeightEnabled: false,
  // 初始容器高度
  initialFrameHeight: 300,
  // 初始容器宽度
  initialFrameWidth: '100%',
  // 上传文件接口
  // serverUrl: 'http://35.201.165.105:8000/controller.php',
  // serverUrl: `${basicUrl}/Public/ueditor/php/controller.php`,
}
// 拼接图片服务器地址
function addHeader (value) {
  return 'https://sunraytiku.oss-cn-beijing.aliyuncs.com/' + value
}

// 正则拼接富文本的url前缀
function replaceAll (value) {
  if (!value) {
    return
  }
  var msg = 'src="/'
  return value.replace(new RegExp(msg, 'g'), `src="${this.$basicUrl}/`)
}
Vue.prototype.$replaceAll = replaceAll
Vue.prototype.$config = ueditorConfig
Vue.prototype.$addHeader = addHeader

Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
